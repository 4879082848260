<template>
  <div v-if="!showModal">
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <!-- 搜索栏 -->
        <a-row>
          <a-form-item label="用户ID" name="id" class="ui-form__item">
            <a-input v-model:value="formState.id" placeholder="请输入用户ID"></a-input>
          </a-form-item>

          <a-form-item label="用户名称" name="nickname" class="ui-form__item">
            <a-input v-model:value="formState.nickname" placeholder="请输入用户名称"></a-input>
          </a-form-item>

          <a-form-item label="用户手机" name="phone" class="ui-form__item">
            <a-input v-model:value="formState.phone" placeholder="请输入用户手机"></a-input>
          </a-form-item>

          <a-form-item label="性别" name="sex" class="ui-form__item">
            <a-select v-model:value="formState.sex" placeholder="请选择性别" allow-clear style="width: 180px;">
              <a-select-option :value="1">男</a-select-option>
              <a-select-option :value="2">女</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="会员等级" name="level" class="ui-form__item">
            <a-select v-model:value="formState.level" placeholder="请选择会员等级" allow-clear style="width: 180px;">
              <a-select-option :value="1">特色会员</a-select-option>
              <a-select-option :value="2">lv.1</a-select-option>
              <a-select-option :value="3">lv.2</a-select-option>
              <a-select-option :value="4">lv.3</a-select-option>
              <a-select-option :value="5">lv.4</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="生日" name="birthday" class="ui-form__item">
            <a-range-picker v-model:value="formState.birthday"></a-range-picker>
          </a-form-item>
        </a-row>

        <a-row>
			<a-col :span="16">
				<exportReport type="baseUserExportStrategy" :searchData="searchData"></exportReport>
			</a-col>
          <a-col :span="8" style="text-align: right">
            <a-button
                style="margin-right: 10px"
                type="primary"
                html-type="submit"
            >搜索</a-button>
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

      <div style="margin-top: 20px;">
        <a-table :pagination="pagination" :columns="columns" :dataSource="list" :rowKey="record => record.key"
                 :scroll="{ x: 3500 }">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'avatar'">
              <a-image :width="50" :src="record.avatar" />
            </template>
            <template v-if="column.key === 'sex'">
              <div v-if="record.sex === 1">男</div>
              <div v-if="record.sex === 2">女</div>
              <div v-if="record.sex === 3">未知</div>
            </template>
            <template v-if="column.key === 'birthday'">
              {{ transDateTime(record.birthday) }}
            </template>
            <template v-if="column.key === 'allPayment'">
              {{ (record.shopGoodsOrderTotalPayment + record.cinemaOrderTotalPayment + record.snackGoodsOrderTotalPayment + record.performOrderTotalPayment).toFixed(2) }}
            </template>
            <template v-if="column.key === 'isDisabled'">
              <a-tag v-if="!record.isDisabled" color="#87d068">解冻</a-tag>
              <a-tag v-if="record.isDisabled" color="#f50">冻结</a-tag>
            </template>

            <template v-if="column.key === 'action'">
              <a-dropdown :trigger="['click', 'hover']">
                <a-button>操作 <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div v-permission="['user_manage_list_property']">
                      <a-menu-item @click="onShowUser(record)">
                        用户详情
                      </a-menu-item>
                    </div>
                    <div v-permission="['user_manage_list_open']" v-if="record.isDisabled" @click="onDisabled(record)">
                      <a-menu-item>
                        解冻
                      </a-menu-item>
                    </div>
                    <div v-permission="['user_manage_list_close']" v-else @click="onDisabled(record)">
                      <a-menu-item>
                        冻结
                      </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </template>
        </a-table>
      </div>
    </a-spin>
  </div>
  <temp v-if="showModal" :id="id" @back="onBack(false)"></temp>
</template>

<script>
import temp from './detail.vue'
import {
  Icon
} from '@/components/icon/icon.js';
import exportReport from '@/components/exportReport/exportReport.vue';
import {disabledUser, enabledUser, getMemberList} from "@/service/modules/member";
export default {
  components: {
    Icon,
	exportReport,
    temp,
  },
  data() {
    return {
      showModal: false,
      isEdit: false,
      isSee: false,
      id: 0,
      loading: false,
      searchData: {},
      formState: {},
      columns: [{
        title: '用户ID',
        dataIndex: 'id',
      }, {
        title: '用户名称',
        dataIndex: 'nickname',
      }, {
        title: '用户手机',
        dataIndex: 'phone',
      }, {
        title: '头像',
        key: 'avatar',
      }, {
        title: '性别',
        key: 'sex',
      }, {
        title: '生日',
        key: 'birthday',
      }, {
        title: '普通会员等级',
        dataIndex: 'memberLevelName',
      }, {
        title: '累计消费',
        key: 'allPayment',
      }, {
        title: '累计影片消费',
        dataIndex: 'cinemaOrderTotalPayment',
      }, {
        title: '累计卖品消费',
        dataIndex: 'snackGoodsOrderTotalPayment',
      }, {
        title: '累计演出消费',
        dataIndex: 'performOrderTotalPayment',
      }, {
        title: '累计商城消费',
        dataIndex: 'shopGoodsOrderTotalPayment',
      }, {
        title: '精选评价条数',
        dataIndex: 'choiceCommentNum',
      }, {
        title: '展示评论条数',
        dataIndex: 'showCommentNum',
      }, {
        title: '积分',
        dataIndex: 'point',
      }, {
        title: '一卡通个数',
        dataIndex: 'unionCardNum',
      }, {
        title: '优惠券张数',
        dataIndex: 'couponNum',
      }, {
        title: '状态',
        key: 'isDisabled',
      }, {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 150
      }],
      list: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
    this.onSearch();
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    async getData() {
      if(this.formState.birthday && this.formState.birthday.length === 2) {
        this.searchData.birthdayStartTime = this.moment(this.formState.birthday[0].startOf('day')).unix();
        this.searchData.birthdayEndTime = this.moment(this.formState.birthday[1].endOf('day')).unix();
      }
      this.loading = true;
      try {
        let ret = await getMemberList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onBack(isRef) {
      this.showModal = false;
      if (isRef) {
        this.$nextTick(() => {
          this.getData();
        })
      }
    },
    onShowUser(item) {
      this.showModal = true;
      this.id = item.id;
    },
    onDisabled(item) {
      this.$confirm({
        title: '提示',
        content: '确认' + (item.isDisabled ? '解冻' : '冻结') + '该用户吗',
        onOk: async () => {
          this.loading = true;
          try {
            let ret;
            if(item.isDisabled) {
              ret = await enabledUser({id: item.id});
            } else {
              ret = await disabledUser({id: item.id});
            }
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success((item.isDisabled ? '解冻' :'冻结') + '成功！');
              this.getData();
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
